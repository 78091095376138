// Colors
$white: #fff !default;
$black: #000 !default;
%clearfix {
  &:before,
  &:after {
    content: "";
    display: table;
  }

  &:after {
    clear: both;
  }
}

@mixin inlineblock($valign: top) {
  display: inline-block;
  vertical-align: $valign;
}

@mixin centerer($horizontal: true, $vertical: true) {
  position: absolute;

  @if ($horizontal and $vertical) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  } @else if ($horizontal) {
    left: 50%;
    transform: translate(-50%, 0);
  } @else if ($vertical) {
    top: 50%;
    transform: translate(0, -50%);
  }
}

@mixin absolute {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

@mixin ghostVerticalAlign() {
  &:before {
    content: "";
    display: inline-block;
    vertical-align: middle;
    height: 100%;
    width: 0.1px;
  }
}

@mixin ghostHorizontalAlign() {
  text-align: justify;
  text-justify: distribute;

  &:after {
    content: "";
    display: inline-block;
    width: 100%;
  }
}

@mixin input-placeholder {
  &.placeholder {
    @content;
  }

  &:-moz-placeholder {
    @content;
  }

  &::-moz-placeholder {
    @content;
  }

  &:-ms-input-placeholder {
    @content;
  }

  &::-webkit-input-placeholder {
    @content;
  }
}

@function rem($size) {
  @return $size / 16px+0rem;
}

@mixin fontSize($size) {
  font-size: $size;
  font-size: rem($size);
}

@mixin desktop {
  @media screen and (max-width: 1200px) {
    @content;
  }
}

@mixin laptop {
  @media screen and (max-width: 992px) {
    @content;
  }
}

@mixin tablets {
  @media screen and (max-width: 768px) {
    @content;
  }
}

@mixin phones {
  @media screen and (max-width: 480px) {
    @content;
  }
}

@mixin imgGlitch($name, $intensity, $width, $height, $top, $left) {
  $steps: $intensity;
  @at-root {
    @for $i from 1 through 2 {
      @keyframes #{$name}-anim-#{$i} {
        @for $i from 0 through $steps {
          #{percentage($i*(1/$steps))} {
            clip: rect(
              random($height) + px,
              $width + px,
              random($height) + px,
              0
            );

            -ms-transform: scale(1.2) skewX(random(30) - 30 + deg);
            -webkit-transform: scale(1.2) skewX(random(30) - 30 + deg);
            transform: scale(1.2) skewX(random(10) - 10 + deg);

            -webkit-filter: saturate(9);
            filter: saturate(9);
          }
        }
      }
    }
  }

  > img {
    position: absolute;
    top: $top + px;
    left: $left + px;
  }
  > img:nth-child(2),
  > img:nth-child(3) {
    clip: rect(0, 0, 0, 0);
  }
  > img:nth-child(2) {
    left: ($left + 2) + px;
    animation: #{$name}-anim-1 2s infinite linear alternate-reverse;
  }
  > img:nth-child(3) {
    left: ($left - 2) + px;
    animation: #{$name}-anim-2 3s infinite linear alternate-reverse;
  }
}



























































.button {
  position: relative;
  border: none;
  background: none;
  height: 48px;
  padding: 0 20px;
  border-radius: 4px;
  font-weight: bold;
  font-size: 14px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.45s ease;
  transition-property: background, color, opacity;
  cursor: pointer;
  overflow: hidden;

  .slider {
    &-enter-active,
    &-leave-active {
      transition: 200ms ease;
      transition-property: transform, opacity;
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }

    &-enter,
    &-leave-to {
      opacity: 0;
      transform: translate3d(0, 100%, 0);
    }
  }

  &[disabled] {
    opacity: 0.5;
    cursor: default;
  }

  &_purple {
    background: #6f61e9;
    border: 1px solid #6f61e9;
    color: #fff;
    &:not([disabled]) {
      &:focus,
      &:hover {
        color: #6f61e9;
        background: none;
      }
    }

    &.not-hover {
      &:hover {
        color: #fff;
        background: #6f61e9;
      }
    }

    &-ghost {
      color: #6f61e9;
      background: rgba(111, 97, 233, 0.1);
      border: 1px solid rgba(111, 97, 233, 0.5);
      &:focus,
      &:hover {
        background: rgba(111, 97, 233, 0.1);
        color: #6f61e9;
      }
    }
  }
  &_small {
    height: 30px;
    padding: 0 15px;
    font-size: 12px;
  }
  &_ghost {
    color: #6f61e9;
  }
}
