// Colors
$white: #fff !default;
$black: #000 !default;
%clearfix {
  &:before,
  &:after {
    content: "";
    display: table;
  }

  &:after {
    clear: both;
  }
}

@mixin inlineblock($valign: top) {
  display: inline-block;
  vertical-align: $valign;
}

@mixin centerer($horizontal: true, $vertical: true) {
  position: absolute;

  @if ($horizontal and $vertical) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  } @else if ($horizontal) {
    left: 50%;
    transform: translate(-50%, 0);
  } @else if ($vertical) {
    top: 50%;
    transform: translate(0, -50%);
  }
}

@mixin absolute {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

@mixin ghostVerticalAlign() {
  &:before {
    content: "";
    display: inline-block;
    vertical-align: middle;
    height: 100%;
    width: 0.1px;
  }
}

@mixin ghostHorizontalAlign() {
  text-align: justify;
  text-justify: distribute;

  &:after {
    content: "";
    display: inline-block;
    width: 100%;
  }
}

@mixin input-placeholder {
  &.placeholder {
    @content;
  }

  &:-moz-placeholder {
    @content;
  }

  &::-moz-placeholder {
    @content;
  }

  &:-ms-input-placeholder {
    @content;
  }

  &::-webkit-input-placeholder {
    @content;
  }
}

@function rem($size) {
  @return $size / 16px+0rem;
}

@mixin fontSize($size) {
  font-size: $size;
  font-size: rem($size);
}

@mixin desktop {
  @media screen and (max-width: 1200px) {
    @content;
  }
}

@mixin laptop {
  @media screen and (max-width: 992px) {
    @content;
  }
}

@mixin tablets {
  @media screen and (max-width: 768px) {
    @content;
  }
}

@mixin phones {
  @media screen and (max-width: 480px) {
    @content;
  }
}

@mixin imgGlitch($name, $intensity, $width, $height, $top, $left) {
  $steps: $intensity;
  @at-root {
    @for $i from 1 through 2 {
      @keyframes #{$name}-anim-#{$i} {
        @for $i from 0 through $steps {
          #{percentage($i*(1/$steps))} {
            clip: rect(
              random($height) + px,
              $width + px,
              random($height) + px,
              0
            );

            -ms-transform: scale(1.2) skewX(random(30) - 30 + deg);
            -webkit-transform: scale(1.2) skewX(random(30) - 30 + deg);
            transform: scale(1.2) skewX(random(10) - 10 + deg);

            -webkit-filter: saturate(9);
            filter: saturate(9);
          }
        }
      }
    }
  }

  > img {
    position: absolute;
    top: $top + px;
    left: $left + px;
  }
  > img:nth-child(2),
  > img:nth-child(3) {
    clip: rect(0, 0, 0, 0);
  }
  > img:nth-child(2) {
    left: ($left + 2) + px;
    animation: #{$name}-anim-1 2s infinite linear alternate-reverse;
  }
  > img:nth-child(3) {
    left: ($left - 2) + px;
    animation: #{$name}-anim-2 3s infinite linear alternate-reverse;
  }
}




























































































































  .input-controls-wrapper {
    display: flex;
    width: 120px;
  }

  .input-controls-status-smev {
    position: relative;
    height: 30px;
    width: 30px;
    margin-top: 10px;
    padding-top: 3px;
    text-align: center;
    border-radius: 15px;
    border-width: 1px;
    border-style: solid;
    color: rgb(255, 255, 255);
    cursor: default;

    &.status_-1 {
      border-color: rgb(245, 56, 59);
      background-color: rgba(245, 56, 59, 0.7);
    }
    &.status_1 {
      border-color: rgb(16, 180, 135);
      background-color: rgba(16, 180, 135, 0.7);
    }
    &.status_0, &.status_2 {
      border-color: rgb(204, 204, 0);
      background-color: rgba(204, 204, 0, 0.7);
    }
    &.status_-2 {
      border-color: rgb(128, 128, 128);
      background-color: rgb(246, 246, 248);
      color: rgb(128, 128, 128);
    }
  }

  .input-controls-btn {
    position: relative;

    &-mini {
      height: 30px;
      width: 30px;
      margin-top: 10px;
      margin-left: 15px;
      text-align: center;
      border-radius: 15px;
      border: none;
      color: rgb(255, 255, 255);
      background-color: rgb(111, 97, 233);
      font-size: 1.3em;
      cursor: pointer;
    }
  }

  .input-controls-info {
    position: relative;

    &-control{
      height: 30px;
      width: 30px;
      text-align: center;
      margin-top: 10px;
      padding-top: 2px;
      border-radius: 15px;
      border: none;
      color: rgb(111, 97, 233);
      background-color: rgba(111, 97, 233, 0.1);
      border: 1px solid rgba(111, 97, 233, 0.5);
      font-size: 1.3em;
      cursor: default;
    }

    &-control:not(:nth-child(1)){
      margin-left: 15px;
    }
  }

.input-controls-tooltip {
    position: absolute;
    top: 50%;
    right: -15px;
    z-index: 3;
    transform: translate(100%, -50%);
    padding: 10px 15px 10px 10px;
    max-width: 180px;
    min-width: 140px;
    background: #6f61e9;
    opacity: 1;
    border-radius: 4px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    font-size: 13px;
    text-align: left;
    line-height: 17px;
    color: #fff;
    &.status-smev {
      max-width: 360px;
    }
    &.btn {
      min-width: 120px;
    }
    &.info {
      max-width: 360px;
    }
    &.fade-enter-active,
    &.fade-leave-active {
      transition: 0.3s ease;
      transition-property: opacity, transform;
    }
    &.fade-enter,
    &.fade-leave-to {
      transform: translate(120%, -50%);
      opacity: 0;
    }
    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 50%;
      margin-left: -7px;
      margin-top: -9px;
      border-top: 9px solid transparent;
      border-bottom: 9px solid transparent;
      border-right: 8px solid #6f61e9;
    }
  }
